<template>
  <div class="footer-block container-wrap">
    <h2 class="mb-5 md:mb-7 padding_wrap">Toute la gammes</h2>
    <div class="footer-block-slider-wrap padding_wrap">
      <VueSlickCarousel ref="carousel"  v-bind="mainSlickOptions">
        <div class="slider-item pr-5">
          <a href="http://matcha.thegiftlab.eu" target="_blank">
            <div class="slider_img img_1 mb-5 sm:mb-7"></div>
            <!--          <img class="slider_img mb-5" src="../assets/img/header_bg_2_1280.jpg" alt="" draggable="false">-->
            <div class="title_wrap flex flex-col justify-center items-left mb-9">
              <span>Selection Japon</span>
              <h2 class="line green font-bold pb-2">Thé vert Matcha</h2>
            </div>
          </a>
        </div>
        <div class="slider-item pr-5">
          <a href="/" target="_blank">
            <div class="slider_img img_2 mb-5 sm:mb-7"></div>
  <!--          <img class="slider_img mb-5" src="../assets/img/header_bg_1_1280.jpg" alt="" draggable="false">-->
            <div class="title_wrap flex flex-col justify-center items-left mb-9">
              <span>Selection England</span>
              <h2 class="line font-bold pb-2">British Earl Grey</h2>
            </div>
          </a>
        </div>
        <div class="slider-item pr-5">
          <div class="slider_img img_3 mb-5 sm:mb-7"></div>
          <!--          <img class="slider_img mb-5" src="../assets/img/header_bg_2_1280.jpg" alt="" draggable="false">-->
          <div class="title_wrap flex flex-col justify-center items-left mb-9">
            <span>Selection Inde</span>
            <h2 class="line pink font-bold pb-2">Thé au jasmin</h2>
          </div>
        </div>
        <template #customPaging>
          <div class="custom-dot">
          </div>
        </template>
      </VueSlickCarousel>
    </div>
    <div class="footer-block-button padding_wrap all mx-1">
      <div class="footer_button py-3.5">
        <h3 class="mx-1">Voir tous nos autres produits</h3>
      </div>
    </div>
    <div class="footer-logo-wrap relative flex justify-center items-center">
      <p class="logo_name big mb-0 mr-4">Tea<br>Time</p>
      <img class="logo_img" src="../assets/img/Leaf_big.svg" alt="" draggable="false">
    </div>
    <div class="footer-arrow-wrap flex justify-end items-center bg-white">
      <div class="footer-arrow-img-wrap flex justify-center items-center" @click="scrollToTop">
        <img class="arrow_img" src="../assets/img/arrow_top.svg" alt="" draggable="false">
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: "footer-block",
  components: { VueSlickCarousel },
  props: ['index'],
  data() {
    return {
      mainSlickOptions: {
        arrows: false,
        dots: true,
        touchThreshold: 100,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        centerMode: true,
        centerPadding: "15px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
        initialSlide: this.index
      }
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  }
}
</script>

<style scoped>

.slick-active .custom-dot {
  background-color: #FFB700;

}

.custom-dot {
  height: 8px;
  width: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin-right: 0;
}


.footer-block-slider-wrap.padding_wrap {
  padding-right: 0!important;
}

.slider_img {
  min-height: 266px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
}
.slider_img.img_1 {
  background: url("../assets/img/header_bg_1_1280.jpg") no-repeat left;
  background-size: cover;
}

.slider_img.img_2 {
  background: url("../assets/img/header_bg_2_1280.jpg") no-repeat left;
  background-size: cover;
}

.slider_img.img_3 {
  background: url("../assets/img/slider_img_3.jpg") no-repeat center;
  background-size: cover;
}

.footer-logo-wrap {
  padding: 60px 0;
  background-color: #1A1A1A;
}

.footer-arrow-img-wrap {
  height: 58px;
  width: 58px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);
  border-radius: 50%;
  margin: 20px;
}

 .footer-block .title_wrap span {
  font-size: calc(10px + 1.5vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
  color: rgba(255, 255, 255, 0.75);
  font-family: 'Jost';
}

 .footer_button {
   border: 1px solid #FFFFFF;
   max-width: 400px;
   width: 100%;
   border-radius: 25px;
   margin: auto;
 }

@media (min-width: 1280px) {
  .footer_button {
    max-width: 600px;
  }
}

@media (min-width: 640px) {
  .slider_img {
    min-height: calc(166px + 30.5vw);
    border-radius: 25px;
  }
}

@media (max-width: 390px) {
  .footer-block .title_wrap span {
    font-size: 12px;
  }
}
</style>