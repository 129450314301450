<template>
  <div class="presentation container-wrap">
    <div class="presentation_top_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Arôme</h2>
      <p>Mangue, Fruit de la passion, et gingembre</p>
      <div class="presentation_top_img_wrap w-full flex justify-between">
        <div class="presentation_top_img-item py-2 pr-4 w-1/3 flex justify-start items-center">
          <img class="presentation_top_img" src="../assets/img/presentation_img_top_1.png" alt="" draggable="false">
        </div>
        <div class="presentation_top_img-item w-1/3 flex justify-center items-center">
          <img class="presentation_top_img" src="../assets/img/presentation_img_top_2.png" alt="" draggable="false">
        </div>
        <div class="presentation_top_img-item py-2 pl-4 w-1/3 flex justify-end items-center">
          <img class="presentation_top_img" src="../assets/img/presentation_img_top_3.png" alt="" draggable="false">
        </div>
      </div>
    </div>
    <div class="presentation_center_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Origine</h2>
      <div class="presentation_center_img_wrap relative w-full flex justify-center items-center">
        <img class="presentation_center_img" src="../assets/img/presentation_map.png" alt="" draggable="false">
        <p class="map-text absolute mb-0">England, Northampton</p>
      </div>
    </div>
    <div class="presentation_bottom_block flex flex-col items-start">
      <div class="presentation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <h2 class="mb-5 md:mb-7">Histoire</h2>
        <p class="mb-0">Le Earl Grey est composé d’un mélange de thés noirs et d’arômes de bergamote.
          Selon la légende, c’est le comte Edward Grey (dont la traduction en anglais est « Earl Grey ») qui popularisa cette recette
          qui consiste à ajouter une tranche de bergamote dans le thé noir.</p>
      </div>
      <div class="presentation_bottom_block_wrap_img full_page_img img_1">
      </div>
      <div class="presentation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p class="mb-0">Pour ce thé Earl Grey, nous avons sélectionné deux thés noirs  connues pour la qualité de leurs thés noirs.
          Ces deux variétés ont des notes corsées et charpentées qui se complètent parfaitement.</p>
      </div>
      <div class="presentation_bottom_block_wrap_img full_page_img img_2">
      </div>
      <div class="presentation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Elles offrent un très bel écrin à la saveur subtilement parfumée de la bergamote. Cet agrume hybride est issu d’un croisement entre le citron vert et l’orange amère. </p>
        <p class="line pb-4 mb-0">Son goût reconnaissable entre mille confère toute sa particularité à notre thé Earl Grey bio.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "presentation"
}
</script>

<style scoped>

.presentation_center_img {
  max-width: 50%;
}


.presentation_center_img_wrap:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  background-color: #FFB700;
  box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.5);
  z-index: 5;
  border-radius: 50%;
  left: 59%;
  bottom: 50.5%;
}
/*.presentation_top_img-item {*/
/*  justify-content: center;*/
/*}*/
.presentation_top_img {
  object-fit: contain;
  max-height: calc(40px + 7.5vw);
}

.presentation_bottom_block_wrap_img {
  width: 100%;
}

.presentation_bottom_block_wrap_img.img_1 {
  background: url("../assets/img/presentation_bottom_img_1_1280.jpg") no-repeat center;
  background-size: cover;
}

.presentation_bottom_block_wrap_img.img_2 {
  background: url("../assets/img/presentation_bottom_img_2_1280.jpg") no-repeat center;
  background-size: cover;
}

@media (min-width: 768px) {
  .presentation_top_img-item {
    justify-content: center;
  }
}

@media (min-width: 640px) {
  .presentation_top_img-item:nth-child(2) {
    justify-content: center;
  }

  .presentation_center_img_wrap:before {
    height: calc(7px + 1.2vw);
    width: calc(7px + 1.2vw);
    left: 61%;
    bottom: 53%;
  }
}

@media (max-width: 420px) {
  .presentation_top_block p {
    max-width: 250px;
  }
}

@media (max-width: 390px) {
  .presentation_top_img {
    max-height: 72px;
  }
}


</style>