<template>
  <div class="degustation container-wrap">
    <div class="degustation_top_block padding_wrap all border_bottom relative flex flex-col items-start">
      <h2 class="mb-5 md:mb-7">Quand ?</h2>
      <div class="degustation_top_img_wrap w-full flex justify-between">
        <div class="degustation_top_img-item w-1/2 flex-col flex justify-start items-center">
          <img class="degustation_top_img mb-5 md:mb-10" src="../assets/img/DayNight.svg" alt="" draggable="false">
          <span class="title_img">Matin et<br>Soir</span>
        </div>
        <div class="degustation_top_img-item w-1/2 flex flex-col justify-start items-center">
          <img class="degustation_top_img mb-5 md:mb-10" src="../assets/img/TeaTimer.svg" alt="" draggable="false">
          <span class="title_img">2 à 3 fois<br>par jour</span>
        </div>
      </div>
    </div>
    <div class="degustation_bottom_block relative flex flex-col items-start">
      <div class="degustation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <h2 class="mb-5 md:mb-7">Avec quoi ?</h2>
        <p>Cookies</p>
        <span class="title_img">Les cookies se marient parfaitement avec ce type de thé et laissent en bouche une réelle douceur</span>
      </div>
      <div class="degustation_bottom_block_wrap_img full_page_img img_1 w-full">
      </div>
      <div class="degustation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Noix et noisettes</p>
        <span class="title_img">Les noix et noisettes  feront ressortir le caractère de votre thé</span>
      </div>
      <div class="degustation_bottom_block_wrap_img full_page_img img_2 w-full">
      </div>
      <div class="degustation_bottom_block_wrap_text padding_wrap all flex flex-col items-start">
        <p>Tarte au pommes</p>
        <span class="title_img">La pomme vous permettra de faire ressortir les saveurs fruitées de votre thé</span>
      </div>
      <div class="degustation_bottom_block_wrap_img full_page_img img_3 w-full"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "degustation"
}
</script>

<style scoped>
.degustation_top_img-item span.title_img {
  color: #FFB700;
}

.degustation_bottom_block_wrap_text span.title_img {
  text-align: left;
}

.degustation_bottom_block_wrap_img.img_1 {
  background: url("../assets/img/degustation_img_1_1280.jpg") no-repeat center;
  background-size: cover;
}

.degustation_bottom_block_wrap_img.img_2 {
  background: url("../assets/img/degustation_img_2_1280.jpg") no-repeat center bottom 32%;
  background-size: cover;
}

.degustation_bottom_block_wrap_img.img_3 {
  background: url("../assets/img/degustation_img_3_1280.jpg") no-repeat left bottom 29%;
  background-size: cover;
  margin-bottom: 30px;
}

@media (min-width: 640px) {
  .degustation_bottom_block_wrap_img.img_3 {
    margin-bottom: 40px;
  }
}

</style>