<template>
  <div id="app" class="container-wrap">
    <Header @nextPage='nextPage' :hash = hash></Header>
    <!--    <Header></Header>-->
    <transition name="fadeFast">
      <Page1 v-if="hash==='presentation'"></Page1>
      <Page2 v-if="hash==='preparation'"></Page2>
      <Page3 v-if="hash==='degustation'"></Page3>
    </transition>
    <Footer :index="0"></Footer>
  </div>
</template>

<script>
import Header from './components/header-block.vue'
import Page1 from './components/presentation'
import Page2 from './components/preparation'
import Page3 from './components/degustation'
import Footer from './components/footer-block'
export default {
  name: 'App',
  components: {
    Header,
    Page1,
    Page2,
    Page3,
    Footer
  },
  data() {
    return {
      pagesHash: {
        1 : 'presentation',
        2 : 'preparation',
        3 : 'degustation'
      },
      page: 1,
      hash: location.hash.substr(1),
    }
  },
  mounted() {
    if (!this.hash) {
      this.hash = this.pagesHash[1]
      location.hash = this.hash
    }
  },
  methods: {
    nextPage (id) {
      console.log(id)
      this.page  = id
      console.log(this.pagesHash[id])
      this.hash = this.pagesHash[id]
      location.hash = this.hash
    }
  }
}
</script>

<style>
.fadeFast-enter-active {
  transition: all 1s;
}
.fadeFast-enter-to, .fadeFast-leave  {
  opacity: 1;
}
.fadeFast-enter, .fadeFast-leave-to {
  opacity: 0;
}

.slick-list {
  padding-left: 0!important;
}
body {
  box-sizing: border-box;
  /*overflow-x: hidden;*/
  scroll-behavior: smooth;
  margin: 0;
}
body.scroll_none {
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  color: #FFFFFF;
}

p {
  color: #FFFFFF;
  font-family: Jost;
  font-size: calc(10px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 40px;
}

h1 {
  color: #FFFFFF;
  font-size: calc(22px + 1.6vw);
  font-weight: 500;
  text-align: left;
  padding-bottom: 8px;
  letter-spacing: 0;
  line-height: 1.3;
  font-family: 'Jost-Medium';
}

.line {
  position: relative;
}

.line:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 4px;
  width: 25px;
  border-radius: 2.5px;
  background-color: #FFB700;
}

.line.pink:before {
  background-color: #8F1458;
}

.line.green:before {
  background-color: #B0D85B;
}

.logo_name {
  color: #FFFFFF;
  font-family: MuseoModerno;
  font-size: calc(10px + 0.6vw);
  letter-spacing: -0.32px;
  line-height: 0.95;
  text-align: center;
}

.logo_name.big {
  font-size: 30px;
  letter-spacing: -0.64px;
  line-height: 0.95;
  text-align: center;
}

span.title_img {
  font-family: Jost-Light;
  font-size: calc(8px + 1.6vw);
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
}

.container-wrap {
  max-width: 1280px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: #000000;
}

.padding_wrap {
  padding-left: 40px;
  padding-right: 40px;
}

.padding_wrap.all {
  padding: 40px;
}

.border_bottom {
  position: relative;
}

.border_bottom:after {
  position: absolute;
  content: '';
  width: calc(100% - 80px);
  height: 1px;
  left: 40px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.25);
}

.border_all {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 18px;
}

.map-text {
  color: #FFFFFF;
  font-family: Jost;
  font-size: calc(4px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
  bottom: 36%;
  right: 18%;
}

h2 {
  color: #FFFFFF;
  font-size: calc(14px + 1.6vw);
  font-weight: 300;
  text-align: left;
  letter-spacing: 0;
  line-height: 1.3;
  font-family: 'Jost-Light';
}

h3 {
  font-family: Jost;
  font-size: calc(6px + 1.6vw);
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
}

.slick-dots {
  padding-right: 40px!important;
  bottom: 0!important;
}

.slick-dots li {
  width: auto!important;
  height: auto!important;
}

.full_page_img {
  height: 220px;
}

@media (min-width: 640px) {
  .full_page_img {
    height: calc(100px + 30.5vw);
  }

  .map-text {
    bottom: 43%;
    right: 28%;
  }
}

@media (max-width: 1024px) {
  .container-wrap {
    max-width: 1024px;
  }
}

@media (max-width: 768px) {
  .container-wrap {
    max-width: 768px;
  }
}

@media (max-width: 640px) {
  .container-wrap {
    max-width: 640px;
  }

  .padding_wrap {
    padding-left: 30px;
    padding-right: 30px;
  }

  .padding_wrap.all {
    padding: 30px;
  }

  .border_bottom:after {
    width: calc(100% - 60px);
    left: 30px;
  }


  p {
    font-size: 20px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 16px;
  }

  .logo_name {
    font-size: 15px;
  }

  .map-text {
    font-size: 15px;
  }

  span.title_img {
    font-size: 18px;
  }

  .slick-dots {
    padding-right: 30px!important;
  }
}

@media (max-width: 390px) {
  .container-wrap {
    max-width: 390px;
  }
}
</style>

